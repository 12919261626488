@import "variables";

.global_box_shadow {
  box-shadow: 0 1px 1px $primary_shadow_color, 0 2px 2px $primary_shadow_color,
    // 0 3px 3px hsl(0deg 0% 0% / 0.075),
    // 0 5px 5px hsl(0deg 0% 0% / 0.075),
    0 5px 5px $primary_shadow_color;
}

.button_primary {
  border: none;
  background-color: $primary_color;
  color: $white;
  font-weight: bolder;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: darken($primary_color, 10%);
  }

  &:active {
    background-color: lighten($primary_color, 10%);
  }
}

.cursor {
  cursor: pointer;
}

.dis_none {
  display: none;
}

.dis_block {
  display: block;
}

.dis_flex {
  display: flex;
}



@keyframes shake {
  0%, 100% {
    padding-left: 0px;
  }
  10%, 30%, 50%, 70%, 90% {
    padding-left: 8px;
  }
  20%, 40%, 60%, 80% {
    padding-left: 0px;
    // transform: translateX(10px);
  }
}
.error_msg {
  color: $red !important; /* Set your desired text color */
  // font-size: 16px; /* Set your desired font size */
  // font-weight: bold; /* Set your desired font weight */
  // padding: 10px;
  // border: 2px solid #ff0000; /* Set your desired border color */
  // border-radius: 5px;
  // margin: 20px;
  animation: shake 0.8s ease-in-out; /* Adjust duration and timing function as needed */
}