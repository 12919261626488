// /* For Webkit browsers (Chrome, Safari) */
@import "variables";

// *::-webkit-scrollbar {
//     width: 2px;
//     z-index: 0;
//   }
  
//   *::-webkit-scrollbar-thumb {
//     width: 2px;
//     background-color: $grey;
//     border-radius: 4px;
//   }
  
//   ::-webkit-scrollbar-track {
//     background: transparent;
//   }
  
//   /* For Firefox */
//   * {
//     scrollbar-color: #333 transparent;
//   }
  
//   /* For IE and Edge */
//   * {
//     -ms-overflow-style: none;
//     scrollbar-width: thin;
//   }
  
//   /* For IE and Edge thumb style */
//   *:hover {
//     scrollbar-color: #333 transparent;
//   }
  
//   /* For IE and Edge track style */
//   *:hover {
//     -ms-overflow-style: scrollbar;
//   }
  

/* For webkit browsers (Chrome, Safari) */
/* Track */
*::-webkit-scrollbar {
  width: 4px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: $grey; /* You can customize the color of the thumb */
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: $dark_grey; /* Change the color on hover if desired */
}

/* Track on hover */
*::-webkit-scrollbar-track:hover {
  background: $white; /* Change the color of the track on hover if desired */
}
