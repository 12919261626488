@import "variables";

main {
  height: calc(100vh - $nav_height - 12px);
  overflow-y: scroll;
  overflow-x: hidden;
  .main_wrapper {
    padding-bottom:40px ;
    .product_card {
      border-bottom: 2px solid $grey;
      .image_box {
        width: 100%;
        height: 300px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;
      }
      .content_box {
        .name {
          color: $dark;
          font-weight: bolder;
          font-size: 18px;
        }
        p {
          margin: 0px 0px 3px 0px;

          .price {
            color: $primary_color;
            font-weight: bolder;
            font-size: 16px;
          }
          .mrp {
            color: $dark_grey;
            font-size: 12px;
            font-weight: 600;
            text-decoration: line-through;
          }
          .discount {
            font-weight: 600;
            color: $green;
            font-size: 12px;
          }
        }
        .retail_tag {
          display: inline-block;
          padding: 3px 6px;
          background-color: $grey;
          border-radius: 3px;
          font-size: 10px;
          font-weight: bolder;
          color: $dark_grey;
        }
      }
    }
  }
}

.filter_btn {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  .filter_btn {
    display: block;
    padding: 4px;
    width: 80%;
    z-index: 1;
    position: fixed; /* Change from absolute to fixed */
    bottom: 10px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%); /* Simplified transform property */
  }

  main {
    .main_wrapper {
      .product_card {
        .image_box {
          height: 230px;
        }
        .content_box {
          .name {
            font-size: 16px;
          }
          p {
            .price {
              font-size: 14px;
            }
          }
          .retail_tag {
            padding: 2px 4px;
            font-size: 9px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575.98px) {
  main {
    .main_wrapper {
      .product_card {
        .image_box {
          height: 200px;
        }
      }
    }
  }
}