@import "variables";

.checkbox-wrapper {
  margin-bottom: 2px;
}
.checkbox-wrapper .control {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
}
.checkbox-wrapper .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-wrapper .control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background: $grey;
}
.checkbox-wrapper .control:hover input ~ .control__indicator,
.checkbox-wrapper .control input:focus ~ .control__indicator {
  background: #ccc;
}
.checkbox-wrapper .control input:checked ~ .control__indicator {
  background: $primary_color;
}
.checkbox-wrapper
  .control:hover
  input:not([disabled]):checked
  ~ .control__indicator,
.checkbox-wrapper .control input:checked:focus ~ .control__indicator {
  background: $primary_color;
}
.checkbox-wrapper .control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.checkbox-wrapper .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-wrapper .control input:checked ~ .control__indicator:after {
  display: block;
}
.checkbox-wrapper .control--checkbox .control__indicator:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkbox-wrapper
  .control--checkbox
  input:disabled
  ~ .control__indicator:after {
  border-color: #7b7b7b;
}
