$primary_color: #e7473c;
$secondary_color: #fff8e3;

$white: #fff;
$dark: #242f36;
$grey: #e6e6e6;
$dark_grey: #525252;
$mid_grey: #9f9595;
$green: #00790c;
$red: #ff0000;

$primary_shadow_color: hsl(220deg 0% 0% / 0.075);

$nav_height: 60px;
$app_max_width: 1350px;

$global_padding: 18px;

$global_border_radius: 8px;

@media (max-width: 575.98px) {
  $nav_height: 70px;
  /* Your styles for extra small devices go here */
}
