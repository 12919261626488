@import "variables";

$sidebar_width: 250px;

aside {
  height: calc(100vh - $nav_height - 12px);
  overflow: hidden;
  .inner_aside {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding: $global_padding;
    .wrapper {
      padding-bottom: 20px;
      h6 {
        color: $dark;
        padding-bottom: 8px;
        margin-bottom: 10px;
        position: relative;
        display: inline-block;
        font-weight: bolder;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: $grey;
        }
      }
      .checkbox-wrapper {
        .control {
          display: flex;
          align-items: center;
          .color_circle {
            width: 16px;
            height: 16px;
            border-radius: 8px;
            display: inline-block;
            margin-right: 4px;
          }
          .border_grey {
            border: 2px solid $grey;
          }
        }
      }
    }
  }
  .inner_overlay {
    display: none;
  }
}

.apply_filter_btn_container {
  display: none;
}

@media only screen and (max-width: 991.98px) {
  .filters_container_mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    // background-color: green;
    margin: 0 !important;
    padding: 0;

    .inner_aside {
      background-color: $white;
      width: $sidebar_width;
      height: 100%;
    }
    .inner_overlay {
      display: block;
      width: calc(100% - $sidebar_width);
      background-color: rgba($color: $dark, $alpha: 0.5);
    }
  }

  .apply_filter_btn_container {
    display: block;
  }
  .apply_filter_btn {
    padding: 8px;
    width: 100%;
  }
}
