@import "variables";

$gap_px: 30px;

.product_detail_container {
  width: 100%;
  height: 0;
  z-index: 10;
  position: fixed;
  top: 100%;
  left: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  animation: showProductPopup 0.2s ease-in-out forwards;

  .cross_container {
    height: 10%;
    background-color: rgba($color: $dark, $alpha: 0.4);
  }

  .product_detail_wrapper {
    height: 90%;
    background-color: $white;
    .product_detail_inner_wrapper {
      height: 100%;
      opacity: 0;
      visibility: hidden;
      max-width: $app_max_width;
      margin: 0px auto;
      padding: $global_padding;

      animation: fadeInContent 0.6s ease-in-out forwards;

      .main {
        overflow: hidden;
        padding: 20px;
        height: 100%;
        overflow-y: scroll;
        .img_list_container {
          .image_box {
            width: 100%;
            height: 100px;
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $white;
            // margin-bottom: $gap_px;
            transition: border 0.3s ease;
            &.active {
              border: 1px solid $primary_color;
            }
          }
        }
        .img_main_container {
          // padding: 0px 20px;
          .image_box {
            width: 100%;
            height: 500px;
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $white;
            margin-bottom: 20px;
          }
        }
        .content_container {
          .name {
            font-size: 24px;
            font-weight: bolder;
            color: $dark;
            margin-bottom: 5px;
          }
          .discription {
            font-size: 18px;
            font-weight: 600;
            color: $mid_grey;
          }
          .divider {
            width: 100%;
            border-bottom: 1px solid $mid_grey;
          }
          .price_wrapper {
            margin: 10px 0px;
            .price {
              font-size: 20px;
              font-weight: bolder;
              color: $dark;
            }
            .original_price {
              font-weight: 600;
              color: $mid_grey;
              margin: 0px 5px 0px 10px;
              text-decoration: line-through;
            }
            .discount {
              font-weight: 600;
              color: $primary_color;
            }
          }
          .heading {
            font-weight: bolder;
            color: $dark;
            margin-bottom: 15px;
          }
          .img_color_container {
            display: flex;
            flex-wrap: wrap;
            .image_box {
              width: 80px;
              height: 100px;
              position: relative;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: $white;
              margin-right: 20px;
              margin-bottom: 20px;
            }
          }
          .select_size_container {
            display: flex;
            flex-wrap: wrap;
            .size_box {
              width: 45px;
              height: 45px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $mid_grey;
              color: $dark;
              font-weight: 600;
              margin-right: 15px;
              margin-bottom: 15px;
              transition: all 0.3s ease;
              &:hover {
                border-color: $primary_color;
              }
              &.selected_box {
                border: 1px solid $primary_color;
                background-color: $primary_color;
                color: $white;
              }
              &.disabled_box {
                border: 1px solid $mid_grey;
                background-color: $mid_grey;
                color: $white;
                &:hover {
                  border-color: $mid_grey;
                }
              }
            }
          }

          select {
            appearance: none;
            text-align: center;
            width: 105px;
            height: 45px;
            font-size: 16px;
            border: 1px solid $mid_grey;
            color: $dark;
            font-weight: 600;
            max-height: 200px;
            cursor: pointer;
            outline: none;
            background-color: $white;
            padding: 0px 15px;
            &:hover {
              border-color: $primary_color;
            }
            &:focus {
              border-color: $primary_color;
              box-shadow: 0 0 3px rgba($primary_color, 0.7);
            }
            option {
              background-color: $white;
              color: #333;
            }
            option:hover {
              background-color: $grey;
            }
          }
          button {
            margin-top: 30px;
            padding: 8px 25px;
          }
        }
      }
    }
  }
}

@keyframes showProductPopup {
  0% {
    top: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    top: 0;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInContent {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  34% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 575.98px) {
  .product_detail_container {
    .product_detail_wrapper {
      .product_detail_inner_wrapper {
        padding: 10px;
        .main {
          padding: 10px;
          .img_main_container {
            .image_box {
              height: 380px;
            }
          }
        }
      }
    }
  }
}
