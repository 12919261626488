@import "variables";

.demo_back_btn_container {
  display: flex;
  justify-content: end;
}

.demo_back_btn {
  padding: 8px 20px;
}
