@import "variables";

header {
    height: $nav_height;
    background-color: $white;
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 1;
    .inner_nav {
      // width: 100%;
      max-width: $app_max_width;
      margin: 0px auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 25px;
      // border-radius: $global_border_radius;
      .logo_box {
        height: 100%;
        img {
          height: 100%;
        }
      }
      .right_nav {
        width: 70px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 70%;
        }
      }
    }
  }