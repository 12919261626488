@import "variables";

.error_wrapper {
  width: 100%;
  height: 350px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $global_padding;
  h6 {
    color: $dark;
    font-weight: bolder;
    text-align: center;
    line-height: 28px;
  }
}
