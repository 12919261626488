@import "variables";

$selector_width: 160px;

.range_selector_wrapper {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $selector_width;
  // background-color: aqua;
  margin: 5px 0px 60px 0px;
  // background-color: red;
  position: relative;
}

.slider {
  position: relative;
  width: $selector_width;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: $grey;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: $primary_color;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: $dark;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 700;
  background-color: $grey;
  padding: 2px 8px;
  min-width: 40px;
  text-align: center;
}

.slider__left-value {
  border-radius: 0px 8px 8px 8px;
  left: 0px;
}

.slider__right-value {
  border-radius: 8px 0px 8px 8px;
  right: 0px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: $selector_width;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: $grey;
  border: none;
  border-radius: 50%;
  // box-shadow: 0 0 1px 1px #ced4da;
  border: 2px solid $dark_grey;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: $grey;
  border: none;
  border-radius: 50%;
  border: 2px solid $dark_grey;
  // box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
