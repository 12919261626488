@import "variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
}

body {
  background-color: $white;
}

.app_wrapper {
  max-width: $app_max_width;
  margin: 0px auto;
  // background-color: red;
  height: calc(100vh - $nav_height);
  // overflow: hidden;
}
